import { Box } from '@mui/material';
import type { GetServerSideProps, NextPage } from 'next';
import LoginForm from '../src/components/forms/LoginForm';
import MainLayout from '../src/components/layouts/MainLayout';
import Seo from '../src/components/Seo';
import { withUserSsr } from '../src/hooks/session';

const Page: NextPage = () => {
  return (
    <MainLayout user={null}>
      <Seo title="ログイン" />
      <Box minHeight="50vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box maxWidth={600} width="100vw">
          <LoginForm />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Page;

export const getServerSideProps: GetServerSideProps = withUserSsr(
  async () => {
    return { props: {} };
  },
  {
    requiresGuest: true,
  }
);
