import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';
import type { FC } from 'react';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import NextImage from 'next/image';
import { firebaseAuth } from '../../config/firebase';
import { loginWithGoogle } from '../../clients/api';
import { getFirstTouchURL, getLastTouchURL } from '../../config/cookie';

interface Props {
  signType: string;
  label: string;
  agreement: boolean;
  size?: ButtonProps['size'];
  onComplete?: () => void;
}

const GoogleLoginForm: FC<Props> = ({ label, signType, agreement, size, onComplete }) => {
  const { enqueueSnackbar } = useSnackbar();
  const googleProvider = new GoogleAuthProvider();
  const router = useRouter();
  const redirectURL: string = router.query.path ? String(router.query.path) : '/account/';

  const SignInWithGoogle = async () => {
    if (!agreement) {
      enqueueSnackbar('利用規約・プライバシーポリシーへの同意をお願いします', { variant: 'error' });
      return;
    }
    try {
      const response = await signInWithPopup(firebaseAuth, googleProvider);
      if (!response) {
        enqueueSnackbar('Google認証がキャンセルされました', { variant: 'error' });
        return;
      }
      if (response.user) {
        const user = response.user;
        const token = await user.getIdToken();
        const res = await loginWithGoogle({
          token: token,
          firstTouchURL: getFirstTouchURL(),
          lastTouchURL: getLastTouchURL(),
        });
        if (!res.success) {
          enqueueSnackbar('認証できませんでした。', { variant: 'error' });
          return;
        }
        enqueueSnackbar('認証が完了しました。', { variant: 'success' });
        if (signType === 'login') {
          if (onComplete) {
            onComplete();
          } else {
            router.push(redirectURL);
          }
        } else {
          router.push('/account/signup/');
        }
      }
    } catch (_) {
      enqueueSnackbar('Google認証がキャンセルされました', { variant: 'error' });
    }
  };

  return (
    <Button
      onClick={SignInWithGoogle}
      variant="contained"
      size={size ? size : 'large'}
      color="secondary"
      sx={{ padding: '15px', fontWeight: 700 }}
    >
      <NextImage src="google.svg" alt="google logo" width={24} height={24} />
      Googleで{label}
    </Button>
  );
};

export default GoogleLoginForm;
