import styled from '@emotion/styled';
import { Button, Link, Stack, TextField, Typography } from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import NextLink from 'next/link';
import { login, resendEmailConfirmation } from '../../clients/api';
import SecretInput from './SecretInput';
import GoogleLoginForm from './GoogleLoginForm';

interface FormValues {
  email: string;
  password: string;
}

const LoginForm: FC = ({}) => {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const redirectURL: string = router.query.path ? String(router.query.path) : '/account';
  const [networkError, setNetworkError] = useState(false);
  const [unconfirmedEmail, setUnconfirmedEmail] = useState(false);
  const [currentValues, setCurrentValues] = useState<FormValues | null>(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  const onSubmit = async (data: FormValues) => {
    setCurrentValues(data);
    const res = await login(data);
    if (res.success) {
      router.push(redirectURL);
    } else if (res.error === 'Email not confirmed') {
      setUnconfirmedEmail(true);
      setNetworkError(false);
    } else {
      setUnconfirmedEmail(false);
      setNetworkError(true);
    }
  };

  const resendConfirmation = async () => {
    if (!currentValues) return;
    let success = false;
    try {
      const res = await resendEmailConfirmation({ email: currentValues.email });
      success = res.success;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      success = false;
    }
    if (success) {
      enqueueSnackbar('認証メールを再送しました。', { variant: 'success' });
    } else {
      enqueueSnackbar('認証メールを再送できませんでした。', { variant: 'error' });
    }
    return;
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack spacing={2}>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            ログイン
          </Typography>
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                variant="outlined"
                type="email"
                fullWidth={true}
                required={true}
                error={!!errors.email}
                helperText={errors.email && 'メールアドレスを入力してください'}
                label="メールアドレス"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{ required: true, minLength: 8 }}
            render={({ field }) => (
              <SecretInput
                variant="outlined"
                fullWidth={true}
                required={true}
                error={!!errors.password}
                helperText={errors.password && '最低 8 文字のパスワードを入力してください'}
                label="パスワード"
                {...field}
              />
            )}
          />
          {networkError && (
            <Typography variant="body2" sx={{ color: 'error.main' }}>
              ログインに失敗しました。
            </Typography>
          )}
          {unconfirmedEmail && (
            <Typography variant="body2" sx={{ color: 'error.main' }}>
              メールアドレスの確認が済んでいません。
              <Link color="error" sx={{ cursor: 'pointer' }} onClick={resendConfirmation}>
                確認メールを再送信する
              </Link>
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{ padding: '15px', fontWeight: 700 }}
          >
            ログイン
          </Button>
          <GoogleLoginForm label="ログイン" signType="login" agreement={true} />
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            <NextLink href="/resetPassword" passHref={true}>
              <Link color="secondary">パスワードをお忘れの方はこちら</Link>
            </NextLink>
          </Typography>
        </Stack>
      </form>
    </Wrapper>
  );
};

export default LoginForm;

const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  background: #fff;
  color: #000;
  padding: 24px;
  width: 100%;
`;
